import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';
// import AuthClassicLayout from 'src/layouts/auth/classic';
import { ExternalRedirect, redirectedLink } from 'src/utils/utils';
import { paths } from '../paths';

// const RegistrationMain = lazy(() => import('src/pages/HSSF/registration/registration'));

export const hssfRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.registration.root,
        children: [
          {
            // element: (
            //   <AuthClassicLayout>
            //     <RegistrationMain />
            //   </AuthClassicLayout>
            // ),
            element: <ExternalRedirect to={redirectedLink?.registration} />,

            index: true,
          },
        ],
      },
    ],
  },
];
