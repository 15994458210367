import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({ baseURL: HOST_API });

// axiosInstance.interceptors.response.use(
//   (res) => res,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

// export default axiosInstance;

export const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers.Authorization = `bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401 && localStorage.getItem('accessToken')) {
      alert(error.response.data.error);
      window.location.href = paths.auth.jwt.login;
      localStorage.removeItem('accessToken');
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong!');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

export const API_END_POINTS = {
  AUTH: {
    LOGIN: '/api/auth/local',
    REGISTER: '/api/auth/local/register',
    NEW_REGISTER: '/api/organizations',
  },
  PRANTS: '/api/prants',
  VIBHAGS: '/api/vibhags',
  JILLAS: '/api/jillas',
  TALUKAS: '/api/talukas',
  PROGRAMME: {
    root: '/api/programs',
    USER: '/api/program-users',
  },
  USER: {
    root: '/api/users',
  },
};

export const auth = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  del: axiosInstance.delete,
};
